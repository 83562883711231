import React from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import UserContext from './UserContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import Spinner from './components/Spinner';
import { messageCode } from './MessageCode';

class ForgotLoginId extends React.Component {
  state = {
    errorMessage: null,
    running: false,
  };
  static contextType = UserContext;
  forgotLoginId = (values) => {
    this.setState({ errorMessage: null, running: true });
    const palam = {
      body: {
        emailaddress: values.email,
        kinmusaki_tel_no: values.phone,
      },
    };
    API.post('HelloOGAPI', '/forgotLoginId', palam)
      .then(() => {
        this.setState({ errorMessage: 'ログインIDを登録されたメールアドレスにお送りしました。', running: false });
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode], running: false });
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
      });
  };
  render() {
    const { running } = this.state;
    return (
      <div className="contents">
        <Spinner isLoading={running} />
        <Header />
        <div className="main-contents">
          <div className="form-col col-lg-5">
            <h1 className="mb-4">ログインIDの確認</h1>
            <p>
              ログインIDをお忘れの場合は、
              <br />
              ご登録されているメールアドレスと
              <br />
              ご登録されている勤務先電話番号を入力してください。
              <br />
              メールアドレス宛にログインIDを送信いたします。
            </p>
            <div className="error-message">
              <p>{this.state.errorMessage}</p>
            </div>
            <Formik
              initialValues={{ email: '', phone: '' }}
              onSubmit={(values) => {
                this.forgotLoginId(values);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email(messageCode['W0007']).required(messageCode['W0001']),
                phone: Yup.string()
                  .matches(/^[0-9]*$/, messageCode['W0006'])
                  .required(messageCode['W0001']),
              })}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form-label-group">
                    <Label for="email">メールアドレス</Label>
                    <Input
                      className="form-control half-width"
                      name="email"
                      id="email"
                      maxLength="256"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      invalid={touched.email && errors.email ? true : false}
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="phone">勤務先電話番号</Label>
                    <Input
                      className="form-control number"
                      type="tel"
                      name="phone"
                      id="phone"
                      maxLength="20"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      invalid={touched.phone && errors.phone ? true : false}
                    />
                    <FormFeedback>{errors.phone}</FormFeedback>
                  </FormGroup>
                  <Button
                    className="btn btn-form text-uppercase font-weight-bold mb-2"
                    variant="primary"
                    size="lg"
                    type="submit"
                    block
                  >
                    ログインIDをメールで確認
                  </Button>
                  <Button
                    className="btn btn-form text-uppercase font-weight-bold mb-2"
                    variant="secondary"
                    size="lg"
                    onClick={() => this.props.switchState('showSignIn')}
                    block
                  >
                    戻る
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default ForgotLoginId;
ForgotLoginId.propTypes = {
  switchState: PropTypes.func,
};
