import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';

const NotFoundPage = (props) => (
  <div className="contents">
    <Header />
    <div className="main-contents">
      <div className="form-col col-lg-7">
        <h1 className="mb-4">ページが見つかりません</h1>
        <div className="errorcode">
          <span>コード　A400</span>
        </div>
        <p className="mb-5">
          指定されたページが見つかりませんでした。
          <br />
          <br />
          申し訳ありませんが、指定されたページは見つかりませんでした。
          <br />
          URLをご確認いただくか、下記のボタンよりご利用ください。
        </p>
        <Button
          className="btn btn-form text-uppercase font-weight-bold mb-2"
          variant="secondary"
          size="lg"
          onClick={() => props.history.replace('/login')}
          block
        >
          トップへ戻る
        </Button>
      </div>
    </div>
    <Footer />
  </div>
);
export default NotFoundPage;
NotFoundPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
