import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';

const Complete = (props) => (
  <div className="contents">
    <Header />
    <div className="main-contents">
      <div className="step-col col-lg-7">
        <div className="step">
          <ul className="progressbar">
            <li className="active">お客様情報入力</li>
            <li className="active">仮登録メール送信</li>
            <li className="active">ご登録完了</li>
          </ul>
        </div>
        <h1 className="mb-4">本登録</h1>
        <h2 className="mb-4">ユーザ登録が完了しました。</h2>
        <p className="mb-5">
          ご登録いただいたメールアドレス宛に
          <br />
          登録完了の確認メールを送信いたしました。
          <br />
          ご確認をお願いいたします。
        </p>
        <Button
          className="btn btn-form text-uppercase font-weight-bold mb-2"
          variant="secondary"
          size="lg"
          onClick={() => props.history.replace('/login')}
          block
        >
          ログイン画面へ戻る
        </Button>
      </div>
    </div>
    <Footer />
  </div>
);
export default Complete;
Complete.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
