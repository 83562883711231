import React, { Component } from 'react';
import { Auth, Hub } from 'aws-amplify';

import './App.css';
import Router from './Router';
import UserContext from './UserContext';

class App extends Component {
  state = {
    currentUser: {},
    isLoaded: false,
  };
  componentDidMount() {
    this.updateCurrentUser();
    Hub.listen('auth', this.listener);
  }
  listener = (data) => {
    if (data.payload.event !== 'configured' && data.payload.event !== 'tokenRefresh_failure') {
      this.updateCurrentUser();
    }
  };
  updateCurrentUser = async () => {
    try {
      const user = await Auth.currentUserInfo();
      this.setState({ currentUser: user, isLoaded: true });
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true });
    }
  };
  render() {
    return (
      <UserContext.Provider
        value={{
          user: this.state.currentUser,
          updateCurrentUser: this.updateCurrentUser,
          isLoaded: this.state.isLoaded,
        }}
      >
        <Router />
      </UserContext.Provider>
    );
  }
}

export default App;
