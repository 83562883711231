import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';

class SessionLost extends React.Component {
  componentDidMount() {
    Auth.signOut();
  }
  render() {
    return (
      <div className="contents">
        <Header />
        <div className="main-contents">
          <div className="form-col col-lg-7">
            <h1 className="mb-4">ログイン情報が無効です</h1>
            <p className="mb-5">
              ログイン情報が無効または有効期限が切れています。
              <br />
              お手数をおかけしますが、ログイン画面から再度ログインをお願いいたします。
            </p>
            <Button
              className="btn btn-form text-uppercase font-weight-bold mb-2"
              variant="secondary"
              size="lg"
              onClick={() => this.props.history.replace('/login')}
              block
            >
              ログイン画面へ戻る
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default SessionLost;
SessionLost.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
