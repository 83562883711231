import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';

const Logout = (props) => (
  <div className="contents">
    <Header />
    <div className="main-contents">
      <div className="form-col col-lg-5">
        <h1 className="mb-4">ログアウト</h1>
        <p className="mb-5">ログアウトしました。</p>
        <Button
          className="btn btn-form text-uppercase font-weight-bold mb-2"
          variant="secondary"
          size="lg"
          onClick={() => props.history.replace('/login')}
          block
        >
          ログイン画面へ戻る
        </Button>
      </div>
    </div>
    <Footer />
  </div>
);
export default Logout;
Logout.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
