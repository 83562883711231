import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormText } from 'reactstrap';

const getMessage = (message) => {
  const msg = message.split('\\n');
  return (
    <>
      {msg.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </>
  );
};

// Input feedback
const InputFeedback = ({ error }) => (error ? <div>{error}</div> : null);
InputFeedback.propTypes = {
  error: PropTypes.string,
};

// Radio input
export const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, ...props }) => {
  return (
    <FormGroup className="radio" check>
      <Label className="ml-5" check>
        <Input
          name={name}
          id={id}
          type="radio"
          bsSize="lg"
          value={id}
          checked={id === value}
          onChange={onChange}
          onBlur={onBlur}
          className="mt-0"
          style={{ width: '20px', marginLeft: '-30px' }}
          {...props}
        />
        {label}
      </Label>
    </FormGroup>
  );
};
RadioButton.propTypes = {
  field: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
};

// Radio group
export const RadioButtonGroup = ({ error, touched, label, sublabel, children, ...props }) => {
  return (
    <FormGroup tag="fieldset" {...props}>
      <Label>{label}</Label>
      <FormText color="muted">{getMessage(sublabel)}</FormText>
      <div className="form-radio-group">
        {children}
        {touched && <InputFeedback error={error} />}
      </div>
    </FormGroup>
  );
};
RadioButtonGroup.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  children: PropTypes.any,
};
