import React from 'react';
import PropTypes from 'prop-types';
import { Auth, API } from 'aws-amplify';
import UserContext from './UserContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback, FormText, InputGroupAddon, InputGroup } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import Spinner from './components/Spinner';
import { messageCode } from './MessageCode';

class ForgotPassword extends React.Component {
  state = {
    errorMessage: null,
    loginid: '',
    currentState: 'inputLoginId',
    running: false,
    displayEyes: {
      password: false,
      confirmPassword: false,
    },
  };
  static contextType = UserContext;

  toggleFishEye = ({ target }, stateName) => {
    target.classList.toggle('fa-eye');
    target.classList.toggle('fa-eye-slash');
    this.setState((prevState) => ({
      displayEyes: {
        ...prevState.displayEyes,
        [stateName]: !this.state.displayEyes[stateName],
      },
    }));
  };

  forgotPassword = (values) => {
    this.setState({ loginid: values.loginid, errorMessage: null, running: true });
    const palam = {
      body: {
        portal_login_id: values.loginid,
        emailaddress: values.email,
      },
    };
    API.post('HelloOGAPI', '/forgotPassword', palam)
      .then(() => {
        this.setState({ currentState: 'inputNewPassword', running: false });
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode], running: false });
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
      });
  };
  forgotPasswordSubmit = (values) => {
    const { loginid } = this.state;
    const { password, confirmationCode } = values;
    this.setState({ errorMessage: null, running: true });
    Auth.forgotPasswordSubmit(loginid, confirmationCode, password)
      .then(() => {
        this.setState({ currentState: 'changeCompleted', running: false });
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        switch (err.code) {
          case 'CodeMismatchException':
            // 無効なコードが入力された場合に起こる。
            // 注) username が存在しない・無効化されている場合にも起こる。
            this.setState({ errorMessage: messageCode['E0022'], running: false });
            break;
          case 'LimitExceededException':
            // コードを間違え続けた場合に起こる。
            this.setState({ errorMessage: messageCode['E0014'], running: false });
            break;
          case 'ExpiredCodeException':
            // コードが期限切れ（1時間をオーバー）した場合に起こる。
            this.setState({ errorMessage: messageCode['E0023'], running: false });
            break;
          case 'InvalidPasswordException':
            // ユーザープールのポリシーで設定したパスワードの強度を満たさない場合に起こる。
            this.setState({ errorMessage: messageCode['E0024'], running: false });
            break;
          case 'UserNotFoundException':
            // ユーザーが削除されている場合
            this.setState({ errorMessage: messageCode['E0002'], running: false });
            break;
          default:
            // その他のエラー
            this.setState({ errorMessage: messageCode['E0001'], running: false });
            break;
        }
      });
  };
  render() {
    const { running, displayEyes } = this.state;
    return (
      <div className="contents">
        <Spinner isLoading={running} />
        <Header />
        <div className="main-contents">
          {this.state.currentState === 'inputLoginId' && (
            <div className="form-col col-lg-5">
              <h1 className="mb-4">パスワード再設定</h1>
              <p>
                ご登録いただいたメールアドレス宛に
                <br />
                パスワード再設定用の確認コードを送信いたします。
                <br />
                確認コードの有効期限は1時間です。
                <br />
                ログインIDとメールアドレスを入力してください。
              </p>
              <div className="error-message">
                <p>{this.state.errorMessage}</p>
              </div>
              <Formik
                initialValues={{ loginid: '', email: '' }}
                onSubmit={(values) => {
                  this.forgotPassword(values);
                }}
                validationSchema={Yup.object().shape({
                  loginid: Yup.string().required(messageCode['W0001']),
                  email: Yup.string().email(messageCode['W0007']).required(messageCode['W0001']),
                })}
              >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="form-label-group">
                      <Label for="loginid">ログインID</Label>
                      <Input
                        className="form-control half-width"
                        name="loginid"
                        id="loginid"
                        maxLength="20"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.loginid}
                        invalid={touched.loginid && errors.loginid ? true : false}
                      />
                      <FormFeedback>{errors.loginid}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="form-label-group">
                      <Label for="email">メールアドレス</Label>
                      <Input
                        className="form-control half-width"
                        name="email"
                        id="email"
                        maxLength="256"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        invalid={touched.email && errors.email ? true : false}
                      />
                      <FormFeedback>{errors.email}</FormFeedback>
                    </FormGroup>
                    <Button
                      className="btn btn-form text-uppercase font-weight-bold mb-2"
                      variant="primary"
                      size="lg"
                      type="submit"
                      block
                    >
                      確認コードの送信
                    </Button>
                    <Button
                      className="btn btn-form text-uppercase font-weight-bold mb-2"
                      variant="secondary"
                      size="lg"
                      onClick={() => this.props.switchState('showSignIn')}
                      block
                    >
                      戻る
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {this.state.currentState === 'inputNewPassword' && (
            <div className="form-col col-lg-5">
              <h1 className="mb-4">パスワード再設定(確認コード入力)</h1>
              <p>
                パスワード再設定メールに記載されている
                <br />
                確認コードと新しいパスワードを入力してください。
                <br />
                確認コードの有効期限は1時間です。
              </p>
              <div className="error-message">
                <p>{this.state.errorMessage}</p>
              </div>
              <Formik
                initialValues={{ confirmationCode: '', password: '', confirmPassword: '' }}
                onSubmit={(values) => {
                  this.forgotPasswordSubmit(values);
                }}
                validationSchema={Yup.object().shape({
                  confirmationCode: Yup.string().required(messageCode['W0001']),
                  password: Yup.string()
                    .required(messageCode['W0001'])
                    .min(12, messageCode['W0003'])
                    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])[!-~]{12,99}$/, messageCode['W0013']),
                  confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password')], messageCode['W0004'])
                    .required(messageCode['W0005']),
                })}
              >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="form-label-group">
                      <Label for="confirmationCode">確認コード</Label>
                      <Input
                        className="form-control half-width"
                        name="confirmationCode"
                        id="confirmationCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmationCode}
                        invalid={touched.confirmationCode && errors.confirmationCode ? true : false}
                      />
                      <FormFeedback>{errors.confirmationCode}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="form-label-group">
                      <Label for="password">新しいパスワード</Label>
                      <InputGroup>
                        <Input
                          className="form-control half-width"
                          type={displayEyes.password ? 'text' : 'password'}
                          name="password"
                          id="password"
                          maxLength="99"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          invalid={touched.password && errors.password ? true : false}
                        />
                        <InputGroupAddon addonType="append">
                          <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'password')} />
                        </InputGroupAddon>
                        <FormFeedback>{errors.password}</FormFeedback>
                      </InputGroup>
                      <FormText color="muted">
                        ※パスワードは英字の大文字/小文字、数字の組み合わせで12文字以上で入力してください
                      </FormText>
                    </FormGroup>
                    <FormGroup className="form-label-group">
                      <Label for="confirmPassword">新しいパスワード（確認用）</Label>
                      <InputGroup>
                        <Input
                          className="form-control half-width"
                          type={displayEyes.confirmPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          id="confirmPassword"
                          maxLength="99"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          invalid={touched.confirmPassword && errors.confirmPassword ? true : false}
                        />
                        <InputGroupAddon addonType="append">
                          <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'confirmPassword')} />
                        </InputGroupAddon>
                        <FormFeedback>{errors.confirmPassword}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                    <Button
                      className="btn btn-form text-uppercase font-weight-bold mb-2"
                      variant="primary"
                      size="lg"
                      type="submit"
                      block
                    >
                      パスワードを再設定する
                    </Button>
                    <Button
                      className="btn btn-form text-uppercase font-weight-bold mb-2"
                      variant="secondary"
                      size="lg"
                      onClick={() => {
                        this.setState({ currentState: 'inputLoginId' });
                        window.scrollTo(0, 0);
                      }}
                      block
                    >
                      戻る
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {this.state.currentState === 'changeCompleted' && (
            <div className="form-col col-lg-5">
              <h1 className="mb-4">パスワード再設定完了</h1>
              <p className="mb-5">パスワードの再設定が完了いたしました。</p>
              <Button
                className="btn btn-form text-uppercase font-weight-bold mb-2"
                variant="secondary"
                size="lg"
                onClick={() => this.props.switchState('showSignIn')}
                block
              >
                ログイン画面へ戻る
              </Button>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}
export default ForgotPassword;
ForgotPassword.propTypes = {
  switchState: PropTypes.func,
};
