import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

// Input feedback
const InputFeedback = ({ error }) => (error ? <div>{error}</div> : null);
InputFeedback.propTypes = {
  error: PropTypes.string,
};

// Checkbox input
export const Checkbox = ({ field: { name, value, onChange, onBlur }, id, label, ...props }) => {
  return (
    <FormGroup check>
      <Label className="ml-5" check>
        <Input
          name={name}
          id={id}
          type="checkbox"
          bsSize="lg"
          value={value}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          style={{ width: '20px', marginTop: 0, marginLeft: -30 }}
          {...props}
        />
        {label}
      </Label>
    </FormGroup>
  );
};
Checkbox.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
};
