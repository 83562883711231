import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import config from './aws-exports';
import Amplify from 'aws-amplify';
Amplify.configure(config);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'HelloOGAPI',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
      },
    ],
  },
});

ReactDOM.render(
  <App />,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
