/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITYPOOLID,
    "aws_cognito_region": process.env.REACT_APP_REGION,
    "aws_user_pools_id": process.env.REACT_APP_USERPOOLID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_USERPOOLWEBCLIENTID,
    "oauth": {},
    "Auth": {
        storage: window.sessionStorage,
    }
};

export default awsmobile;
