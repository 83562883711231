import React from 'react';
import PropTypes from 'prop-types';
import { Auth, API } from 'aws-amplify';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback, FormText, InputGroupAddon, InputGroup } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Spinner from './components/Spinner';
import Recaptcha from 'react-invisible-recaptcha';
import Header from './Header';
import Footer from './Footer';
import { RadioButtonGroup, RadioButton } from './components/RadioButton';
import { Checkbox } from './components/Checkbox';
import { Confirmation } from './components/Confirmation';
import { messageCode } from './MessageCode';
import $ from 'jquery';
import 'jquery-jpostal-ja';

class SignUp extends React.Component {
  values = null;
  state = {
    errorMessage: null,
    showConfirmation: false,
    prefectureList: [],
    industryList: [],
    employeeList: [],
    occupationList: [],
    positionList: [],
    running: false,
    confirm: false,
    displayEyes: {
      password: false,
      confirmPassword: false,
    },
  };
  signUp = (values) => {
    this.values = values;
    this.setState({ errorMessage: null });
    this.recaptcha.execute();
    //this.onResolved();
  };
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);
  }

  toggleFishEye = ({ target }, stateName) => {
    target.classList.toggle('fa-eye');
    target.classList.toggle('fa-eye-slash');
    this.setState((prevState) => ({
      displayEyes: {
        ...prevState.displayEyes,
        [stateName]: !this.state.displayEyes[stateName],
      },
    }));
  };

  componentDidMount() {
    // 都道府県マスタの取得
    API.get('HelloOGAPI', '/master/prefectures')
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.tdfkn_cd, disp: element.tdfkn_nm_knj });
        });
        this.setState({ prefectureList: list });
        $('#postCode').jpostal({
          postcode: ['#postCode'],
          address: {
            '#prefecture': '%3',
            '#address': '%4%5',
          },
        });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 業種マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0001',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ industryList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 従業員規模マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0002',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ employeeList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 職種マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0003',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ occupationList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 職位マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0004',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ positionList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
  }
  onResolved() {
    const {
      loginid,
      password,
      companyName,
      companyName_kana,
      department,
      postCode,
      prefecture,
      address,
      phone,
      industry,
      employee,
      occupation,
      position,
      sei_knj,
      mei_knj,
      sei_kana,
      mei_kana,
      email,
      mfa,
      info,
    } = this.values;
    this.setState({ running: true });
    Auth.signUp({
      username: loginid,
      password,
      attributes: {
        email,
      },
      clientMetadata: {
        token: this.recaptcha.getResponse(),
        companyName,
        companyName_kana,
        department,
        postCode,
        prefecture,
        address,
        phone,
        industry,
        employee,
        occupation,
        position,
        sei_knj,
        mei_knj,
        sei_kana,
        mei_kana,
        mfa,
        info,
        rec_mod_pgm_id: 'P202001',
      },
    })
      .then(() => {
        this.setState({ showConfirmation: true, running: false });
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        switch (err.code) {
          case 'UsernameExistsException':
            this.setState({ errorMessage: messageCode['E0010'], running: false });
            break;
          case 'UserLambdaValidationException': {
            const code = err.message.match(/E\d+/);
            if (code) {
              this.setState({ errorMessage: messageCode[code[0]], running: false });
            } else {
              this.setState({ errorMessage: messageCode['E0001'], running: false });
            }
            break;
          }
          default:
            // その他のエラー
            this.setState({ errorMessage: messageCode['E0001'], running: false });
            break;
        }
        window.scrollTo(0, 0);
        this.recaptcha.reset();
      });
  }
  render() {
    const {
      showConfirmation,
      prefectureList,
      industryList,
      employeeList,
      occupationList,
      positionList,
      running,
      confirm,
      displayEyes,
    } = this.state;
    return (
      <div className="contents">
        <Spinner isLoading={running} />
        <Header />
        <div className="step-col">
          <div className="row justify-content-center no-gutters pl-4 pr-4">
            {!showConfirmation && (
              <div className="col-lg-7">
                <div className="step">
                  <ul className="progressbar">
                    <li className="active">お客様情報入力</li>
                    <li className="">仮登録メール送信</li>
                    <li className="">ご登録完了</li>
                  </ul>
                </div>
                <div className="area-title">
                  <h2>ユーザ登録</h2>
                </div>
                <div className="error-message">
                  <p>{this.state.errorMessage}</p>
                </div>
                <Formik
                  initialValues={{
                    loginid: '',
                    password: '',
                    confirmPassword: '',
                    companyName: '',
                    companyName_kana: '',
                    department: '',
                    postCode: '',
                    prefecture: '00',
                    address: '',
                    phone: '',
                    industry: '00',
                    employee: '00',
                    occupation: '00',
                    position: '00',
                    sei_knj: '',
                    mei_knj: '',
                    sei_kana: '',
                    mei_kana: '',
                    email: '',
                    confirmEmail: '',
                    mfa: 'mfaOff',
                    info: 'infoOn',
                    agree: false,
                  }}
                  onSubmit={() => {
                    this.setState({ confirm: true });
                  }}
                  validationSchema={Yup.object().shape({
                    loginid: Yup.string()
                      .required(messageCode['W0001'])
                      .min(6, messageCode['W0002'])
                      .matches(/^[0-9a-zA-Z]*$/, messageCode['W0014']),
                    password: Yup.string()
                      .required(messageCode['W0001'])
                      .min(12, messageCode['W0003'])
                      .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])[!-~]{12,99}$/, messageCode['W0013']),
                    confirmPassword: Yup.string()
                      .oneOf([Yup.ref('password')], messageCode['W0004'])
                      .required(messageCode['W0005']),
                    companyName: Yup.string()
                      .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                      .required(messageCode['W0001']),
                    companyName_kana: Yup.string()
                      .matches(/^([ァ-ン]|ー)+$/, messageCode['W0012'])
                      .required(messageCode['W0001']),
                    department: Yup.string().matches(/^[^\x20-\x7e]*$/, messageCode['W0011']),
                    postCode: Yup.string()
                      .matches(/^[0-9]*$/, messageCode['W0006'])
                      .required(messageCode['W0001']),
                    prefecture: Yup.string().notOneOf(['00'], messageCode['W0010']),
                    address: Yup.string()
                      .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                      .required(messageCode['W0001']),
                    phone: Yup.string()
                      .matches(/^[0-9]*$/, messageCode['W0006'])
                      .required(messageCode['W0001']),
                    industry: Yup.string().notOneOf(['00'], messageCode['W0010']),
                    employee: Yup.string().notOneOf(['00'], messageCode['W0010']),
                    occupation: Yup.string().notOneOf(['00'], messageCode['W0010']),
                    position: Yup.string().notOneOf(['00'], messageCode['W0010']),
                    sei_knj: Yup.string()
                      .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                      .required(messageCode['W0001']),
                    mei_knj: Yup.string()
                      .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                      .required(messageCode['W0001']),
                    sei_kana: Yup.string()
                      .matches(/^([ァ-ン]|ー)+$/, messageCode['W0012'])
                      .required(messageCode['W0001']),
                    mei_kana: Yup.string()
                      .matches(/^([ァ-ン]|ー)+$/, messageCode['W0012'])
                      .required(messageCode['W0001']),
                    email: Yup.string().email(messageCode['W0007']).required(messageCode['W0001']),
                    confirmEmail: Yup.string()
                      .oneOf([Yup.ref('email')], messageCode['W0004'])
                      .required(messageCode['W0005']),
                    mfa: Yup.string().required(messageCode['W0008']),
                    info: Yup.string().required(messageCode['W0008']),
                    agree: Yup.bool().oneOf([true], messageCode['W0009']),
                  })}
                >
                  {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="form-label-group">
                        <Label for="loginid" className="required">
                          ログインID
                        </Label>
                        <Input
                          className="form-control half-width"
                          name="loginid"
                          id="loginid"
                          maxLength="20"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.loginid}
                          invalid={touched.loginid && errors.loginid ? true : false}
                        />
                        <FormFeedback>{errors.loginid}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="password" className="required">
                          パスワード
                        </Label>
                        <InputGroup>
                          <Input
                            className="form-control half-width"
                            type={displayEyes.password ? 'text' : 'password'}
                            name="password"
                            id="password"
                            maxLength="99"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            invalid={touched.password && errors.password ? true : false}
                          />
                          <InputGroupAddon addonType="append">
                            <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'password')} />
                          </InputGroupAddon>
                          <FormFeedback>{errors.password}</FormFeedback>
                        </InputGroup>
                        <FormText color="muted">
                          ※パスワードは英字の大文字/小文字、数字の組み合わせで12文字以上で入力してください
                        </FormText>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="confirmPassword">パスワード（確認用）</Label>
                        <InputGroup>
                          <Input
                            className="form-control half-width"
                            type={displayEyes.confirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            id="confirmPassword"
                            maxLength="99"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            invalid={touched.confirmPassword && errors.confirmPassword ? true : false}
                          />
                          <InputGroupAddon addonType="append">
                            <i
                              className="fa fa-eye fish-eye"
                              onClick={(e) => this.toggleFishEye(e, 'confirmPassword')}
                            />
                          </InputGroupAddon>
                          <FormFeedback>{errors.confirmPassword}</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="companyName" className="required">
                          会社名・団体名等
                        </Label>
                        <Input
                          className="form-control full-width"
                          name="companyName"
                          id="companyName"
                          maxLength="30"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyName}
                          invalid={touched.companyName && errors.companyName ? true : false}
                        />
                        <FormText color="muted">
                          ※法人格（「株式会社」など）は略さず、正式名称で入力してください
                        </FormText>
                        <FormFeedback>{errors.companyName}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="companyName_kana" className="required">
                          会社名・団体名等（カナ）
                        </Label>
                        <Input
                          className="form-control full-width"
                          name="companyName_kana"
                          id="companyName_kana"
                          maxLength="60"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyName_kana}
                          invalid={touched.companyName_kana && errors.companyName_kana ? true : false}
                        />
                        <FormText color="muted">※法人格（「カブシキガイシャ」など）は入力しないでください</FormText>
                        <FormText color="muted">全角カタカナのみで入力してください</FormText>
                        <FormFeedback>{errors.companyName_kana}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="department">部署名</Label>
                        <Input
                          className="form-control full-width"
                          name="department"
                          id="department"
                          maxLength="30"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.department}
                          invalid={touched.department && errors.department ? true : false}
                        />
                        <FormFeedback>{errors.department}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="postCode" className="required">
                          郵便番号
                        </Label>
                        <Input
                          className="form-control number"
                          name="postCode"
                          id="postCode"
                          maxLength="7"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                            values.prefecture = $('#prefecture').val();
                            values.address = $('#address').val();
                          }}
                          value={values.postCode}
                          invalid={touched.postCode && errors.postCode ? true : false}
                        />
                        <FormFeedback>{errors.postCode}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="prefecture" className="required">
                          都道府県
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          name="prefecture"
                          id="prefecture"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prefecture}
                          invalid={touched.prefecture && errors.prefecture ? true : false}
                        >
                          {prefectureList.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.disp}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{errors.prefecture}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="address" className="required">
                          住所
                        </Label>
                        <Input
                          className="form-control full-width"
                          name="address"
                          id="address"
                          maxLength="120"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          invalid={touched.address && errors.address ? true : false}
                        />
                        <FormFeedback>{errors.address}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="phone" className="required">
                          電話番号
                        </Label>
                        <Input
                          className="form-control number"
                          type="tel"
                          name="phone"
                          id="phone"
                          maxLength="20"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          invalid={touched.phone && errors.phone ? true : false}
                        />
                        <FormFeedback>{errors.phone}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="industry" className="required">
                          業種
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          name="industry"
                          id="industry"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.industry}
                          invalid={touched.industry && errors.industry ? true : false}
                        >
                          {industryList.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.disp}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{errors.industry}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="employee" className="required">
                          従業員規模
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          name="employee"
                          id="employee"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employee}
                          invalid={touched.employee && errors.employee ? true : false}
                        >
                          {employeeList.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.disp}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{errors.employee}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="occupation" className="required">
                          職種
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          name="occupation"
                          id="occupation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.occupation}
                          invalid={touched.occupation && errors.occupation ? true : false}
                        >
                          {occupationList.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.disp}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{errors.occupation}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="position" className="required">
                          職位
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          name="position"
                          id="position"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.position}
                          invalid={touched.position && errors.position ? true : false}
                        >
                          {positionList.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.disp}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{errors.position}</FormFeedback>
                      </FormGroup>
                      <div className="form-row">
                        <FormGroup className="form-label-group col-sm-6">
                          <Label for="sei_knj" className="required">
                            お名前（姓）
                          </Label>
                          <Input
                            className="form-control full-width"
                            name="sei_knj"
                            id="sei_knj"
                            maxLength="15"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sei_knj}
                            invalid={touched.sei_knj && errors.sei_knj ? true : false}
                          />
                          <FormFeedback>{errors.sei_knj}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="form-label-group col-sm-6">
                          <Label for="mei_knj" className="required">
                            お名前（名）
                          </Label>
                          <Input
                            className="form-control full-width"
                            name="mei_knj"
                            id="mei_knj"
                            maxLength="15"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mei_knj}
                            invalid={touched.mei_knj && errors.mei_knj ? true : false}
                          />
                          <FormFeedback>{errors.mei_knj}</FormFeedback>
                        </FormGroup>
                      </div>
                      <div className="form-row">
                        <FormGroup className="form-label-group col-sm-6">
                          <Label for="sei_kana" className="required">
                            お名前（カナ）（姓）
                          </Label>
                          <Input
                            className="form-control full-width"
                            name="sei_kana"
                            id="sei_kana"
                            maxLength="30"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sei_kana}
                            invalid={touched.sei_kana && errors.sei_kana ? true : false}
                          />
                          <FormFeedback>{errors.sei_kana}</FormFeedback>
                          <FormText color="muted">全角カタカナのみで入力してください</FormText>
                        </FormGroup>
                        <FormGroup className="form-label-group col-sm-6">
                          <Label for="mei_kana" className="required">
                            お名前（カナ）（名）
                          </Label>
                          <Input
                            className="form-control full-width"
                            name="mei_kana"
                            id="mei_kana"
                            maxLength="30"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mei_kana}
                            invalid={touched.mei_kana && errors.mei_kana ? true : false}
                          />
                          <FormFeedback>{errors.mei_kana}</FormFeedback>
                        </FormGroup>
                      </div>
                      <FormGroup className="form-label-group">
                        <Label for="email" className="required">
                          メールアドレス
                        </Label>
                        <Input
                          className="form-control half-width"
                          name="email"
                          id="email"
                          maxLength="256"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          invalid={touched.email && errors.email ? true : false}
                        />
                        <FormFeedback>{errors.email}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <Label for="confirmEmail">メールアドレス（確認用）</Label>
                        <Input
                          className="form-control half-width"
                          name="confirmEmail"
                          id="confirmEmail"
                          maxLength="256"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmEmail}
                          invalid={touched.confirmEmail && errors.confirmEmail ? true : false}
                        />
                        <FormFeedback>{errors.confirmEmail}</FormFeedback>
                      </FormGroup>
                      <RadioButtonGroup
                        label="二段階認証のご希望"
                        sublabel="※ログインごとにご登録いただいたメールアドレス宛にセキュリティコードを送信いたします。\n二段階認証を希望しない場合は一部のサービスが利用できません。\n詳しくはよくあるご質問をご確認ください。"
                        value={values.mfa}
                        error={errors.mfa}
                        touched={touched.mfa}
                        className="form-label-group mt-5"
                      >
                        <Field component={RadioButton} name="mfa" id="mfaOn" label="希望する" />
                        <Field component={RadioButton} name="mfa" id="mfaOff" label="希望しない" />
                      </RadioButtonGroup>
                      <FormGroup className="form-label-group mt-5">
                        <Label>■お客さま情報の取り扱い</Label>
                        <FormText color="muted">
                          今回ご入力いただいたお客さま情報は、大阪ガス株式会社（以下、「当社」）が取得し、HelloOG!ポータルの
                          <a href={process.env.REACT_APP_RIYOKIYAKU_16_URL} rel="noreferrer" target="_blank">
                            利用規約
                          </a>
                          第16条に定める利用目的のために利用し、当該利用規約に基づいて、適正に保管・管理させていただきます。
                        </FormText>
                      </FormGroup>
                      <RadioButtonGroup
                        label="■メールによるお知らせ"
                        sublabel="「希望する」にチェックを入れていただいたお客さまには、お客さま情報の共同利用先であるDaigasグループ会社のDaigasエナジー株式会社より、お客さまの営む事業に関する当社およびDaigasグループ会社の商品・サービス（これらに関連するセミナー・イベントを含む。）のお知らせ・PRをメールにて配信させていただきます。\n例）低炭素・脱炭素、省エネサービスやエネルギー情勢などに関するお役立ち情報をお知らせする「ぐぐっとDaigasエナジー通信」（不定期配信）"
                        value={values.info}
                        error={errors.info}
                        touched={touched.info}
                        className="form-label-group mt-5"
                      >
                        <Field component={RadioButton} name="info" id="infoOn" label="希望する" />
                        <Field component={RadioButton} name="info" id="infoOff" label="希望しない" />
                      </RadioButtonGroup>
                      <FormGroup className="form-label-group mb-4">
                        <FormText color="muted">
                          <div className="fs14">
                            <p>※配信メール記載の宛先へ配信停止を連絡された場合は、配信を停止いたします。</p>
                          </div>
                        </FormText>
                      </FormGroup>
                      <FormGroup className="form-label-group">
                        <div>
                          <a href={process.env.REACT_APP_RIYOKIYAKU_URL} rel="noreferrer" target="_blank">
                            利用規約
                          </a>
                        </div>
                        <Field
                          component={Checkbox}
                          name="agree"
                          id="agree"
                          label="利用規約を確認しました"
                          className="form-label-group"
                        />
                      </FormGroup>
                      <Button
                        className="btn btn-form text-uppercase font-weight-bold mb-2"
                        variant="primary"
                        size="lg"
                        type="submit"
                        disabled={!isValid || !dirty}
                        block
                      >
                        登録
                      </Button>
                      <Confirmation
                        show={confirm}
                        message="ユーザ登録を行います。よろしいですか？"
                        onApply={() => {
                          this.setState({ confirm: false });
                          this.signUp(values);
                        }}
                        onHide={() => this.setState({ confirm: false })}
                      />
                      <Recaptcha
                        ref={(ref) => (this.recaptcha = ref)}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                        onResolved={this.onResolved}
                        onError={() => this.setState({ errorMessage: messageCode['E0029'], running: false })}
                        onExpired={() => this.setState({ errorMessage: messageCode['E0028'], running: false })}
                      />
                      <Button
                        className="btn btn-form text-uppercase font-weight-bold mb-2"
                        variant="secondary"
                        size="lg"
                        onClick={() => this.props.switchState('showSignIn')}
                        block
                      >
                        ログイン画面へ戻る
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
            {showConfirmation && (
              <div className="col-lg-7">
                <div className="step">
                  <ul className="progressbar">
                    <li className="active">お客様情報入力</li>
                    <li className="active">仮登録メール送信</li>
                    <li className="">ご登録完了</li>
                  </ul>
                </div>
                <h1 className="mb-4">仮登録</h1>
                <h2 className="mb-4">仮登録を受け付けました</h2>
                <p className="mb-5">
                  まだ登録は完了しておりません。
                  <br />
                  ご登録いただいたメールアドレス宛に仮登録の確認メールを送信しております。
                  <br />
                  メール記載のリンクをクリックし、本登録をお願いいたします。
                </p>
                <Button
                  className="btn btn-form text-uppercase font-weight-bold mb-2"
                  variant="secondary"
                  size="lg"
                  onClick={() => window.open('about:blank', '_self').close()}
                  block
                >
                  閉じる
                </Button>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default SignUp;
SignUp.propTypes = {
  switchState: PropTypes.func,
};
