import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const getMessage = (message) => {
  const msg = message.split('\\n');
  return (
    <>
      {msg.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </>
  );
};

export function Confirmation(props) {
  return (
    <Modal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>{getMessage(props.message)}</Modal.Body>
      <Modal.Footer>
        <Button size="lg" onClick={props.onApply}>
          はい
        </Button>
        <Button size="lg" onClick={props.onHide}>
          いいえ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
Confirmation.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  onApply: PropTypes.func,
  onHide: PropTypes.func,
};
