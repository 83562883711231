import React from 'react';
import PropTypes from 'prop-types';
import { Auth, API } from 'aws-amplify';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import UserContext from './UserContext';
import { messageCode } from './MessageCode';
import { LinkedServiceThumb } from './components/LinkedService';

const banner1 = null;
const banner2 = null;
/*
const banner1 = [
  {
    title: 'バナー1',
    image: 'banner1/slide_1.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー2',
    image: 'banner1/slide_2.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー3',
    image: 'banner1/slide_3.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー4',
    image: 'banner1/slide_4.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー5',
    image: 'banner1/slide_5.png',
    url: 'https://www.google.com',
  },
];

const banner2 = [
  {
    title: 'バナー1',
    image: 'banner2/slide_1.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー2',
    image: 'banner2/slide_2.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー3',
    image: 'banner2/slide_3.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー4',
    image: 'banner2/slide_4.png',
    url: 'https://www.google.com',
  },
  {
    title: 'バナー5',
    image: 'banner2/slide_5.png',
    url: 'https://www.google.com',
  },
];
*/

class Home extends React.Component {
  static contextType = UserContext;
  state = {
    errorMessage: null,
    loadedOshirase: false,
    loadedService: false,
    infoList: [],
    linkedService: [],
    iFrameHeight: 1000,
  };

  adjustIframe = () => {
    let ifrm = this.iframeRef;
    ifrm = ifrm.contentWindow || ifrm.contentDocument.document || ifrm.contentDocument;
    const scrollHeight = ifrm.document.documentElement.scrollHeight;
    this.setState({ iFrameHeight: scrollHeight });
  };

  async componentDidMount() {
    // お知らせの取得
    API.get('HelloOGAPI', '/info', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    })
      .then((response) => {
        this.setState({ infoList: response.results, loadedOshirase: true });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });

    // ポータルユーザー連携済サービス情報取得
    API.get('HelloOGAPI', '/portaluser/linkedservice', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      queryStringParameters: {
        portal_user_no: Number(this.context.user.attributes['custom:user_no']),
      },
    })
      .then((response) => {
        this.setState({ linkedService: response.results, loadedService: true });
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
        } else {
          this.setState({ errorMessage: messageCode['E0001'] });
        }
      });
  }

  async getCredential({ link_system_cd, link_system_id, portal_user_no }) {
    // JWT取得
    try {
      const params = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
        queryStringParameters: {
          portal_user_no,
          link_system_cd,
          link_system_id,
          portal_login_id: this.context.user.username,
          rec_mod_pgm_id: 'P201001',
        },
      };
      const response = await API.get('HelloOGAPI', '/credential', params);

      const wabParams = {
        portal_user_no: Number(this.context.user.attributes['custom:user_no']),
        state: response.results.state,
      };
      const strParams = Buffer.from(JSON.stringify(wabParams))
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');

      // URLエンコード
      const encodeUrl = encodeURI(response.results.link_system_url);

      // 企業顧客認証基盤（WAB）リクエスト
      const url = `${process.env.REACT_APP_WAB_URL}/login/index.php?action_login_do=true&authcd=${response.results.authorization_code}&params=${strParams}&p=${encodeUrl}`;

      window.open(url, 'wabWindow');
    } catch (err) {
      if (err.response && 'status' in err.response) {
        this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
      } else {
        this.setState({ errorMessage: messageCode['E0001'] });
      }
      return;
    }
  }

  render() {
    const { errorMessage, loadedOshirase, loadedService, infoList, linkedService } = this.state;
    const slickSettings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      pauseOnHover: true,
      pauseOnDotsHover: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4500,
    };
    return (
      <div className="home">
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
        <div className="row justify-content-center no-gutters pl-4 pr-4">
          <div className="col-lg-8 pad_lg_r50">
            {/* お知らせ */}
            <div className="notification-box">
              <div className="area-title">
                <h2>HelloOG!ポータルからのお知らせ</h2>
              </div>
              {loadedOshirase && (
                <ul className="notification-container pl-3">
                  {infoList.length <= 0 ? (
                    <p>HelloOG!ポータルからのお知らせはありません。</p>
                  ) : (
                    infoList.map((item, index) => (
                      <li key={index} className="news_item">
                        <div className="new_icon">
                          {item.new_sgn && <i className="material-icons material-icons-outlined">fiber_new</i>}
                        </div>
                        <div className="news_text">
                          {item.link_url ? (
                            <a href={item.link_url} rel="noreferrer" target="_blank">
                              <pre>{item.oshirase_naiyo_knj}</pre>
                            </a>
                          ) : (
                            <pre>{item.oshirase_naiyo_knj}</pre>
                          )}
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              )}
            </div>
            {process.env.REACT_APP_FREE_CONTENTS_URL !== '' && (
              <div className="mt-5 mb-5">
                <iframe
                  ref={(ref) => (this.iframeRef = ref)}
                  id="freeContent"
                  onLoad={() => this.adjustIframe()}
                  scrolling="no"
                  src={process.env.REACT_APP_FREE_CONTENTS_URL}
                  height={this.state.iFrameHeight}
                >
                  (IFRAME 機能を有効にして下さい)
                </iframe>
              </div>
            )}
          </div>

          <div className="col-lg-4 mb-5">
            <div className="service-link-container">
              <div className="area-title">
                <h2>
                  各種リンク　
                  <a className="" onClick={() => this.props.history.replace('/link')}>
                    <i className="fa fa-link"></i>
                  </a>
                </h2>
              </div>
              {linkedService.length > 0 ? (
                <>
                  <div className="comment pl-1 h3">
                    <b>各種サービスのログイン画面へのリンクです。</b>
                    <br />
                    各サービスの利用規約もしくは契約内容に同意の上ご利用ください。
                    <br />
                    各サービスの
                    <a href={process.env.REACT_APP_SERVICE_RIYOKIYAKU_URL} rel="noreferrer" target="_blank">
                      利用規約はこちらから
                    </a>
                    ご確認ください。
                  </div>
                  <div className="service-link pl-2">
                    {linkedService.map((item, index) => (
                      <LinkedServiceThumb
                        key={index}
                        serviceCode={item.link_system_cd}
                        dispName={item.hyoji_mei_knj}
                        authID={item.link_system_id}
                        imagePath={item.logo_image_path}
                        onEnter={() => {
                          this.getCredential(item);
                        }}
                      />
                    ))}
                  </div>
                </>
              ) : (
                loadedService && (
                  <div className="comment pl-1 h3">
                    <p>
                      リンク設定ボタン
                      <i className="fa fa-link"></i>
                      から各種サービスへのログイン用のリンクバナーの設定が可能です。
                      <br />
                      ご設定いただきますとこの部分にリンクバナーが表示されます。
                      <br />
                      一度ご設定いただくと次回からはＩＤ・ＰＷをご入力いただく必要がございません。
                      <br />
                      設定可能なサービスはリンク設定の中に掲載しております。
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {banner1 && banner2 && (
          <div className="row justify-content-center no-gutters pl-2">
            <div className="col-lg-8 pad_lg_r50">
              {/* バナー */}
              <div className="row mb-2 mt-5 justify-content-center no-gutters">
                <Slider {...slickSettings} className="col-lg-4 slider">
                  {banner1.map((item, index) => (
                    <div
                      key={index}
                      className="slide-img text-center"
                      onClick={() => {
                        window.open(item.url);
                      }}
                    >
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/${item.image}`} />
                        {item.title !== '' && <p>{item.title}</p>}
                      </div>
                    </div>
                  ))}
                </Slider>
                <Slider {...slickSettings} className="col-lg-4 slider">
                  {banner2.map((item, index) => (
                    <div
                      key={index}
                      className="slide-img text-center"
                      onClick={() => {
                        window.open(item.url);
                      }}
                    >
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/${item.image}`} />
                        {item.title !== '' && <p>{item.title}</p>}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        )}
      </div>
    );
  }
}
export default Home;
Home.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
