const messageCode = {
  W0001: '必須項目です',
  W0002: '6文字以上で入力してください',
  W0003: '12文字以上で入力してください',
  W0004: '一致しません',
  W0005: '確認のために入力してください',
  W0006: '数字を入力してください',
  W0007: 'メールアドレスの形式が正しくありません',
  W0008: 'どちらか選択してください',
  W0009: '利用規約をご確認ください',
  W0010: '選択してください',
  W0011: '全角で入力してください',
  W0012: '全角カタカナで入力してください',
  W0013: '大文字小文字数字すべて1つ以上含む必要があります',
  W0014: '半角英数字で入力してください',

  E0001: 'サーバーの処理に失敗しました',
  E0002: 'ログインIDが存在しません',
  E0003: 'ログインIDが存在しないまたはパスワードが誤っています',
  E0004: 'ログインIDがロックされています。しばらくしてからログインしてください',
  E0005: '本登録されていません',
  E0006: 'セキュリティコードが違います。正しいセキュリティコードを入力してください',
  E0007: 'セキュリティコードが無効です。再度ログインをやり直してください',
  E0008: 'リクエストパラメータが不正です',
  E0009: 'データがありません',
  E0010: 'ログインIDは既に使用されています',
  E0011: '画像認証に失敗しました。もう一度やり直してください',
  E0012: 'ログインIDが存在しないまたはメールアドレスが誤っています',
  E0013: '現在のパスワードが違います。パスワードの変更ができませんでした',
  E0014: 'ログインIDがロックされたため、パスワードの変更ができません。しばらくしてから変更してください',
  E0015: '二段階認証を「希望しない」にすることはできません',
  E0016: '登録サービスの上限を超えています',
  E0017: 'このサービスは二段階認証が必須です',
  E0018: '有効なリンク先サービスがありません',
  E0019: '更新できませんでした。既に別ユーザーにより更新されている可能性があります',
  E0020: 'メールアドレスは既に使用されています',
  E0021: 'リンク先サービスの有効期限が切れておりますので、再登録してください',
  E0022: '確認コードが違います',
  E0023: '確認コードの有効期限が切れました',
  E0024: 'パスワードの設定条件を満たしていません。大文字小文字数字すべて1つ以上含む必要があります',
  E0025: 'すでにサービスリンク登録されています',
  E0026: 'メールの送信に失敗しました',
  E0027: 'パスワードは変更されましたが、その他の項目の登録に失敗しました',
  E0028: '画像認証の処理でタイムアウトが発生しました。もう一度やり直してください',
  E0029: '画像認証の処理が失敗しました。もう一度やり直してください',
  E0030: 'メールアドレスまたは勤務先電話番号が誤っています',
  E1400: 'リンク登録が失敗しました。入力情報に不正な内容が検出されました。',
  E1500: 'リンク登録が失敗しました。サーバーの処理に失敗しました。',

  I0001: '正常に終了しました',
  I0002: 'ユーザ登録の情報の更新が完了しました',
};

module.exports = {
  messageCode,
};
