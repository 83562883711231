import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => (
  <Container className="footer" fluid>
    <Row className="text-center pt-5">
      <Col md={6}>
        <p>Copyright &copy; OSAKA GAS CO.,LTD. All rights reserved.</p>
      </Col>
      <Col md={6} className="foot-link-container">
        <ul>
          <li>
            <a className="foot-link" href={process.env.REACT_APP_RIYOKIYAKU_URL} rel="noreferrer" target="_blank">
              ご利用規約
            </a>
          </li>
          <li>
            <a className="foot-link" href={process.env.REACT_APP_FAQ_URL} rel="noreferrer" target="_blank">
              よくあるご質問
            </a>
          </li>
          <li>
            <a className="foot-link" href={process.env.REACT_APP_INQUIRY_URL} rel="noreferrer" target="_blank">
              お問い合わせ
            </a>
          </li>
          <li>
            <a className="foot-link" href={process.env.REACT_APP_PRIVACY_POLICY} rel="noreferrer" target="_blank">
              プライバシーポリシー
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  </Container>
);
export default Footer;
