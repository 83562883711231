import React from 'react';
import PropTypes from 'prop-types';
import { Auth, API } from 'aws-amplify';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback, FormText, InputGroupAddon, InputGroup } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Spinner from './components/Spinner';
import { RadioButtonGroup, RadioButton } from './components/RadioButton';
import { Confirmation } from './components/Confirmation';
import { messageCode } from './MessageCode';
import UserContext from './UserContext';
import $ from 'jquery';
import 'jquery-jpostal-ja';

const getCode = (attrList, type_cd) => {
  if (attrList) {
    for (const attr of attrList) {
      if (attr['attr_type_cd'] === type_cd) return attr['attr_cd'];
    }
  }
  return '00';
};

class UserConfig extends React.Component {
  static contextType = UserContext;
  state = {
    errorMessage: null,
    data: {
      portal_login_id: '',
      kaisha_nm_knj: '',
      kaisha_nm_kana: '',
      kaisha_busho_nm_knj: '',
      kinmusaki_yubin_no: '',
      kinmusaki_tdfkn_cd: '',
      kinmusaki_addr_knj: '',
      kinmusaki_tel_no: '',
      attr: [],
      sei_knj: '',
      mei_knj: '',
      sei_kana: '',
      mei_kana: '',
      emailaddress: '',
      nidankai_auth_yohi_sgn: true,
      email_magazine_recv_sgn: true,
    },
    prefectureList: [],
    industryList: [],
    employeeList: [],
    occupationList: [],
    positionList: [],
    running: false,
    confirm: false,
    displayEyes: {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    },
  };

  getConfig = async () => {
    // ユーザーデータの取得
    API.get('HelloOGAPI', '/portaluser', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      queryStringParameters: {
        portal_user_no: Number(this.context.user.attributes['custom:user_no']),
      },
    })
      .then((response) => {
        this.setState({ data: response.results });
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode], running: false });
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
      });
  };

  toggleFishEye = ({ target }, stateName) => {
    target.classList.toggle('fa-eye');
    target.classList.toggle('fa-eye-slash');
    this.setState((prevState) => ({
      displayEyes: {
        ...prevState.displayEyes,
        [stateName]: !this.state.displayEyes[stateName],
      },
    }));
  };

  changeConfig = async (values) => {
    const { updateCurrentUser } = this.context;
    const user = await Auth.currentAuthenticatedUser();
    const param = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        portal_user_no: Number(user.attributes['custom:user_no']),
        accessToken: user.signInUserSession.accessToken.jwtToken,
        kaisha_nm_knj: values.companyName,
        kaisha_nm_kana: values.companyName_kana,
        kaisha_busho_nm_knj: values.department,
        kinmusaki_yubin_no: values.postCode,
        kinmusaki_tdfkn_cd: values.prefecture,
        kinmusaki_addr_knj: values.address,
        kinmusaki_tel_no: values.phone,
        sei_knj: values.sei_knj,
        mei_knj: values.mei_knj,
        sei_kana: values.sei_kana,
        mei_kana: values.mei_kana,
        nidankai_auth_yohi_sgn: values.mfa === 'mfaOn' ? true : false,
        email_magazine_recv_sgn: values.info === 'infoOn' ? true : false,
        attr: [
          { attr_type_cd: '0001', attr_cd: values.industry },
          { attr_type_cd: '0002', attr_cd: values.employee },
          { attr_type_cd: '0003', attr_cd: values.occupation },
          { attr_type_cd: '0004', attr_cd: values.position },
        ],
        portal_login_id: user.username,
        rec_mod_ctl_no: this.state.data.rec_mod_ctl_no,
        rec_mod_pgm_id: 'P204001',
      },
    };
    if (values.oldPassword !== '' && values.newPassword !== '') {
      param.body.oldPassword = values.oldPassword;
      param.body.newPassword = values.newPassword;
    }
    this.setState({ errorMessage: null, running: true });
    API.post('HelloOGAPI', '/portaluser', param)
      .then(async () => {
        this.getConfig();
        this.setState({ errorMessage: messageCode['I0002'], running: false });
        updateCurrentUser();
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode], running: false });
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
        window.scrollTo(0, 0);
      });
  };

  componentDidMount() {
    // 都道府県マスタの取得
    API.get('HelloOGAPI', '/master/prefectures')
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.tdfkn_cd, disp: element.tdfkn_nm_knj });
        });
        this.setState({ prefectureList: list });
        $('#postCode').jpostal({
          postcode: ['#postCode'],
          address: {
            '#prefecture': '%3',
            '#address': '%4%5',
          },
        });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 業種マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0001',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ industryList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 従業員規模マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0002',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ employeeList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 職種マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0003',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ occupationList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // 職位マスタの取得
    API.get('HelloOGAPI', '/master/attribute', {
      queryStringParameters: {
        attr_type_cd: '0004',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.attr_cd, disp: element.attr_nm_knj });
        });
        this.setState({ positionList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // ユーザーデータの取得
    this.getConfig();
  }

  render() {
    const {
      data,
      prefectureList,
      industryList,
      employeeList,
      occupationList,
      positionList,
      running,
      confirm,
      displayEyes,
    } = this.state;
    return (
      <div className="form-col">
        <Spinner isLoading={running} />
        <div className="row justify-content-center no-gutters pl-4 pr-4">
          <div className="col-lg-7">
            <div className="area-title">
              <h2>ユーザ情報</h2>
            </div>
            <div className="error-message">
              <p>{this.state.errorMessage}</p>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                loginid: data.portal_login_id,
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                companyName: data.kaisha_nm_knj,
                companyName_kana: data.kaisha_nm_kana,
                department: data.kaisha_busho_nm_knj,
                postCode: data.kinmusaki_yubin_no,
                prefecture: data.kinmusaki_tdfkn_cd,
                address: data.kinmusaki_addr_knj,
                phone: data.kinmusaki_tel_no,
                industry: getCode(data.attr, '0001'),
                employee: getCode(data.attr, '0002'),
                occupation: getCode(data.attr, '0003'),
                position: getCode(data.attr, '0004'),
                sei_knj: data.sei_knj,
                mei_knj: data.mei_knj,
                sei_kana: data.sei_kana,
                mei_kana: data.mei_kana,
                email: data.emailaddress,
                mfa: data.nidankai_auth_yohi_sgn === true ? 'mfaOn' : 'mfaOff',
                info: data.email_magazine_recv_sgn === true ? 'infoOn' : 'infoOff',
              }}
              onSubmit={() => {
                this.setState({ confirm: true });
              }}
              validationSchema={Yup.object().shape({
                oldPassword: Yup.string().test('changepass', messageCode['W0001'], function (value) {
                  if (value === undefined && this.parent.newPassword !== undefined) {
                    return false;
                  } else {
                    return true;
                  }
                }),
                newPassword: Yup.string()
                  .min(12, messageCode['W0003'])
                  .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])[!-~]{12,99}$/, messageCode['W0013'])
                  .test('changepass', messageCode['W0001'], function (value) {
                    if (value === undefined && this.parent.oldPassword !== undefined) {
                      return false;
                    } else {
                      return true;
                    }
                  }),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref('newPassword')], messageCode['W0004'])
                  .test('changepass', messageCode['W0005'], function (value) {
                    if (value === undefined && this.parent.newPassword !== undefined) {
                      return false;
                    } else {
                      return true;
                    }
                  }),
                companyName: Yup.string()
                  .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                  .required(messageCode['W0001']),
                companyName_kana: Yup.string()
                  .matches(/^([ァ-ン]|ー)+$/, messageCode['W0012'])
                  .required(messageCode['W0001']),
                department: Yup.string().matches(/^[^\x20-\x7e]*$/, messageCode['W0011']),
                postCode: Yup.string()
                  .matches(/^[0-9]*$/, messageCode['W0006'])
                  .required(messageCode['W0001']),
                prefecture: Yup.string().notOneOf(['00'], messageCode['W0010']),
                address: Yup.string()
                  .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                  .required(messageCode['W0001']),
                phone: Yup.string()
                  .matches(/^[0-9]*$/, messageCode['W0006'])
                  .required(messageCode['W0001']),
                industry: Yup.string().notOneOf(['00'], messageCode['W0010']),
                employee: Yup.string().notOneOf(['00'], messageCode['W0010']),
                occupation: Yup.string().notOneOf(['00'], messageCode['W0010']),
                position: Yup.string().notOneOf(['00'], messageCode['W0010']),
                sei_knj: Yup.string()
                  .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                  .required(messageCode['W0001']),
                mei_knj: Yup.string()
                  .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
                  .required(messageCode['W0001']),
                sei_kana: Yup.string()
                  .matches(/^([ァ-ン]|ー)+$/, messageCode['W0012'])
                  .required(messageCode['W0001']),
                mei_kana: Yup.string()
                  .matches(/^([ァ-ン]|ー)+$/, messageCode['W0012'])
                  .required(messageCode['W0001']),
                mfa: Yup.string().required(messageCode['W0008']),
                info: Yup.string().required(messageCode['W0008']),
              })}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-label-group">
                    <Label for="loginid">ログインID</Label>
                    <p>{values.loginid}</p>
                  </div>
                  <FormGroup className="form-label-group">
                    <Label for="oldPassword">現在のパスワード</Label>
                    <InputGroup>
                      <Input
                        className="form-control half-width"
                        type={displayEyes.oldPassword ? 'text' : 'password'}
                        name="oldPassword"
                        id="oldPassword"
                        maxLength="99"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        invalid={touched.oldPassword && errors.oldPassword ? true : false}
                      />
                      <InputGroupAddon addonType="append">
                        <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'oldPassword')} />
                      </InputGroupAddon>
                      <FormFeedback>{errors.oldPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="newPassword">新しいパスワード</Label>
                    <InputGroup>
                      <Input
                        className="form-control half-width"
                        type={displayEyes.newPassword ? 'text' : 'password'}
                        name="newPassword"
                        id="newPassword"
                        maxLength="99"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        invalid={touched.newPassword && errors.newPassword ? true : false}
                      />
                      <InputGroupAddon addonType="append">
                        <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'newPassword')} />
                      </InputGroupAddon>
                      <FormFeedback>{errors.newPassword}</FormFeedback>
                    </InputGroup>
                    <FormText color="muted">
                      ※パスワードは英字の大文字/小文字、数字の組み合わせで12文字以上で入力してください
                    </FormText>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="confirmPassword">新しいパスワード（確認用）</Label>
                    <InputGroup>
                      <Input
                        className="form-control half-width"
                        type={displayEyes.confirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        id="confirmPassword"
                        maxLength="99"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        invalid={touched.confirmPassword && errors.confirmPassword ? true : false}
                      />
                      <InputGroupAddon addonType="append">
                        <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'confirmPassword')} />
                      </InputGroupAddon>
                      <FormFeedback>{errors.confirmPassword}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="companyName" className="required">
                      会社名・団体名等
                    </Label>
                    <Input
                      className="form-control full-width"
                      name="companyName"
                      id="companyName"
                      maxLength="30"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName}
                      invalid={touched.companyName && errors.companyName ? true : false}
                    />
                    <FormText color="muted">※法人格（「株式会社」など）は略さず、正式名称で入力してください</FormText>
                    <FormFeedback>{errors.companyName}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="companyName_kana" className="required">
                      会社名・団体名等（カナ）
                    </Label>
                    <Input
                      className="form-control full-width"
                      name="companyName_kana"
                      id="companyName_kana"
                      maxLength="60"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName_kana}
                      invalid={touched.companyName_kana && errors.companyName_kana ? true : false}
                    />
                    <FormText color="muted">※法人格（「カブシキガイシャ」など）は入力しないでください</FormText>
                    <FormText color="muted">全角カタカナのみで入力してください</FormText>
                    <FormFeedback>{errors.companyName_kana}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="department">部署名</Label>
                    <Input
                      className="form-control full-width"
                      name="department"
                      id="department"
                      maxLength="30"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.department}
                      invalid={touched.department && errors.department ? true : false}
                    />
                    <FormFeedback>{errors.department}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="postCode" className="required">
                      郵便番号
                    </Label>
                    <Input
                      className="form-control number"
                      name="postCode"
                      id="postCode"
                      maxLength="7"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        values.prefecture = $('#prefecture').val();
                        values.address = $('#address').val();
                      }}
                      value={values.postCode}
                      invalid={touched.postCode && errors.postCode ? true : false}
                    />
                    <FormFeedback>{errors.postCode}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="prefecture" className="required">
                      都道府県
                    </Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="prefecture"
                      id="prefecture"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prefecture}
                      invalid={touched.prefecture && errors.prefecture ? true : false}
                    >
                      {prefectureList.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.disp}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.prefecture}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="address" className="required">
                      住所
                    </Label>
                    <Input
                      className="form-control full-width"
                      name="address"
                      id="address"
                      maxLength="120"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      invalid={touched.address && errors.address ? true : false}
                    />
                    <FormFeedback>{errors.address}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="phone" className="required">
                      電話番号
                    </Label>
                    <Input
                      className="form-control number"
                      type="tel"
                      name="phone"
                      id="phone"
                      maxLength="20"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      invalid={touched.phone && errors.phone ? true : false}
                    />
                    <FormFeedback>{errors.phone}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="industry" className="required">
                      業種
                    </Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="industry"
                      id="industry"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.industry}
                      invalid={touched.industry && errors.industry ? true : false}
                    >
                      {industryList.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.disp}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.industry}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="employee" className="required">
                      従業員規模
                    </Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="employee"
                      id="employee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employee}
                      invalid={touched.employee && errors.employee ? true : false}
                    >
                      {employeeList.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.disp}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.employee}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="occupation" className="required">
                      職種
                    </Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="occupation"
                      id="occupation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.occupation}
                      invalid={touched.occupation && errors.occupation ? true : false}
                    >
                      {occupationList.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.disp}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.occupation}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="form-label-group">
                    <Label for="position" className="required">
                      職位
                    </Label>
                    <Input
                      className="form-control"
                      type="select"
                      name="position"
                      id="position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.position}
                      invalid={touched.position && errors.position ? true : false}
                    >
                      {positionList.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.disp}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.position}</FormFeedback>
                  </FormGroup>
                  <div className="form-row">
                    <FormGroup className="form-label-group col-sm-6">
                      <Label for="sei_knj" className="required">
                        お名前（姓）
                      </Label>
                      <Input
                        className="form-control full-width"
                        name="sei_knj"
                        id="sei_knj"
                        maxLength="15"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.sei_knj}
                        invalid={touched.sei_knj && errors.sei_knj ? true : false}
                      />
                      <FormFeedback>{errors.sei_knj}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="form-label-group col-sm-6">
                      <Label for="mei_knj" className="required">
                        お名前（名）
                      </Label>
                      <Input
                        className="form-control full-width"
                        name="mei_knj"
                        id="mei_knj"
                        maxLength="15"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mei_knj}
                        invalid={touched.mei_knj && errors.mei_knj ? true : false}
                      />
                      <FormFeedback>{errors.mei_knj}</FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup className="form-label-group col-sm-6">
                      <Label for="sei_kana" className="required">
                        お名前（カナ）（姓）
                      </Label>
                      <Input
                        className="form-control full-width"
                        name="sei_kana"
                        id="sei_kana"
                        maxLength="30"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.sei_kana}
                        invalid={touched.sei_kana && errors.sei_kana ? true : false}
                      />
                      <FormFeedback>{errors.sei_kana}</FormFeedback>
                      <FormText color="muted">全角カタカナのみで入力してください</FormText>
                    </FormGroup>
                    <FormGroup className="form-label-group col-sm-6">
                      <Label for="mei_kana" className="required">
                        お名前（カナ）（名）
                      </Label>
                      <Input
                        className="form-control full-width"
                        name="mei_kana"
                        id="mei_kana"
                        maxLength="30"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mei_kana}
                        invalid={touched.mei_kana && errors.mei_kana ? true : false}
                      />
                      <FormFeedback>{errors.mei_kana}</FormFeedback>
                    </FormGroup>
                  </div>
                  <FormGroup className="form-label-group">
                    <Label for="email">メールアドレス</Label>
                    <p>{values.email}</p>
                    <FormText color="muted">変更されたい場合はよくある質問をご確認ください</FormText>
                  </FormGroup>
                  <RadioButtonGroup
                    label="二段階認証のご希望"
                    sublabel="※ログインごとにご登録いただいたメールアドレス宛にセキュリティコードを送信いたします。\n二段階認証が必須のサービスをリンク設定している場合は、『希望しない』を選択できません。"
                    value={values.mfa}
                    error={errors.mfa}
                    touched={touched.mfa}
                    className="form-label-group mt-5"
                  >
                    <Field component={RadioButton} name="mfa" id="mfaOn" label="希望する" />
                    <Field component={RadioButton} name="mfa" id="mfaOff" label="希望しない" />
                  </RadioButtonGroup>
                  <FormGroup className="form-label-group mt-5">
                    <Label>■お客さま情報の取り扱い</Label>
                    <FormText color="muted">
                      今回ご入力いただいたお客さま情報は、大阪ガス株式会社（以下、「当社」）が取得し、HelloOG!ポータルの
                      <a href={process.env.REACT_APP_RIYOKIYAKU_16_URL} rel="noreferrer" target="_blank">
                        利用規約
                      </a>
                      第16条に定める利用目的のために利用し、当該利用規約に基づいて、適正に保管・管理させていただきます。
                    </FormText>
                  </FormGroup>
                  <RadioButtonGroup
                    label="■メールによるお知らせ"
                    sublabel="「希望する」にチェックを入れていただいたお客さまには、お客さま情報の共同利用先であるDaigasグループ会社のDaigasエナジー株式会社より、お客さまの営む事業に関する当社およびDaigasグループ会社の商品・サービス（これらに関連するセミナー・イベントを含む。）のお知らせ・PRをメールにて配信させていただきます。\n例）低炭素・脱炭素、省エネサービスやエネルギー情勢などに関するお役立ち情報をお知らせする「ぐぐっとDaigasエナジー通信」（不定期配信）"
                    value={values.info}
                    error={errors.info}
                    touched={touched.info}
                    className="form-label-group mt-5"
                  >
                    <Field component={RadioButton} name="info" id="infoOn" label="希望する" />
                    <Field component={RadioButton} name="info" id="infoOff" label="希望しない" />
                  </RadioButtonGroup>
                  <FormGroup className="form-label-group mb-4">
                    <FormText color="muted">
                      <div className="fs14">
                        <p>※配信メール記載の宛先へ配信停止を連絡された場合は、配信を停止いたします。</p>
                      </div>
                    </FormText>
                  </FormGroup>
                  <Button
                    className="btn btn-form text-uppercase font-weight-bold mb-2"
                    variant="primary"
                    size="lg"
                    type="submit"
                    block
                  >
                    登録情報の更新
                  </Button>
                  <Confirmation
                    show={confirm}
                    message="登録情報を更新します。よろしいですか？"
                    onApply={() => {
                      this.setState({ confirm: false });
                      this.changeConfig(values);
                    }}
                    onHide={() => this.setState({ confirm: false })}
                  />
                  <Button
                    className="btn btn-form text-uppercase font-weight-bold mb-2"
                    variant="secondary"
                    size="lg"
                    onClick={() => this.props.history.replace('/')}
                    block
                  >
                    戻る
                  </Button>
                </Form>
              )}
            </Formik>
            <p className="mt-5">ユーザ登録の削除を希望される方はこちらから</p>
            <Button
              className="btn btn-form text-uppercase font-weight-bold mb-2"
              variant="secondary"
              size="lg"
              onClick={() => this.props.history.replace('/withdrawal')}
              block
            >
              ユーザ登録の削除
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default UserConfig;
UserConfig.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
