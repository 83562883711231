import React from 'react';
import { Auth, API } from 'aws-amplify';
import { Input } from 'reactstrap';
import { Buffer } from 'buffer';

import UserContext from './UserContext';
import { messageCode } from './MessageCode';
import { Service, LinkedService } from './components/LinkedService';
import { Confirmation } from './components/Confirmation';

class LinkConfig extends React.Component {
  static contextType = UserContext;
  values = null;
  mfa = false;
  state = {
    errorMessage: null,
    warnMessage: null,
    serviceList: [],
    linkedService: [],
    selectService: '00',
    confirmRegist: false,
    confirmRelease: false,
  };

  async componentDidMount() {
    this.getData();
    this.regCoLinkAuth();
  }

  async regCoLinkAuth() {
    try {
      const qs = new URLSearchParams(window.location.search);

      const responseErrorCode = qs.get('error');
      if (responseErrorCode) {
        this.setState({ errorMessage: messageCode['E1'.concat(responseErrorCode)] });
        return;
      }

      const state = qs.get('state'),
        authorization_code = qs.get('authorization_code'),
        portal_user_no = qs.get('portal_user_no'),
        link_system_cd = qs.get('link_system_cd'),
        hyoji_mei_knj = qs.get('hyoji_mei_knj');

      if (!(state && authorization_code && portal_user_no && link_system_cd && hyoji_mei_knj)) {
        return;
      }

      const params = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
        body: {
          state: state,
          authorization_code: authorization_code,
          portal_user_no: Number(portal_user_no),
          link_system_cd: link_system_cd,
          hyoji_mei_knj: hyoji_mei_knj,
          rec_mod_pgm_id: 'P203001',
        },
      };
      await API.post('HelloOGAPI', '/credential', params);
      window.location.replace('/link');
    } catch (err) {
      if (err.response && 'status' in err.response) {
        this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
      } else {
        this.setState({ errorMessage: messageCode['E0001'] });
      }
      return;
    }
  }

  async getData() {
    // サービス一覧の取得
    API.get('HelloOGAPI', '/master/service')
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください', img: '', mfa: false }];
        response.results.forEach((element) => {
          list.push({
            code: element.link_system_cd,
            disp: element.link_system_nm_knj,
            img: element.logo_image_path,
            mfa: element.nidankai_auth_hissu_sgn,
          });
        });
        this.setState({ serviceList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // ポータルユーザー連携済サービス情報取得
    API.get('HelloOGAPI', '/portaluser/linkedservice', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      queryStringParameters: {
        portal_user_no: Number(this.context.user.attributes['custom:user_no']),
      },
    })
      .then((response) => {
        this.setState({ linkedService: response.results });
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
        } else {
          this.setState({ errorMessage: messageCode['E0001'] });
        }
      });
    // ユーザーデータの取得
    API.get('HelloOGAPI', '/portaluser', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      queryStringParameters: {
        portal_user_no: Number(this.context.user.attributes['custom:user_no']),
      },
    })
      .then((response) => {
        this.mfa = response.results.nidankai_auth_yohi_sgn;
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
        } else {
          this.setState({ errorMessage: messageCode['E0001'] });
        }
      });
  }

  render() {
    const { serviceList, linkedService, selectService, confirmRegist, confirmRelease } = this.state;
    return (
      <div className="form-col">
        <div className="row justify-content-center no-gutters pl-4 pr-4">
          <div className="col-lg-9">
            <p>
              こちらから、トップページの各種リンク欄に各種サービスへのログイン用のリンクバナーの設定が可能です。
              <br />
              こちらでID・PWをご設定いただくと次回からご入力いただく必要はございません。
              <br />
              リンクバナーの設定可能なサービスは下記のリンクするサービスよりご確認ください。
            </p>
            <p>※二段階認証を必須とするリンクバナーを利用する場合は二段階認証の設定を有効にしてください</p>
            <p>※最大50件のサービスの設定が可能です</p>
            <div className="error-message">
              <p>{this.state.errorMessage}</p>
            </div>
            <div className="area-title mt-5">
              <h2>リンクするサービス</h2>
              <div className="form-label-group pb-4">
                <Input
                  className="form-control"
                  type="select"
                  onChange={(e) => {
                    let msgcode = null;
                    if (e.target.value !== '00') {
                      if (this.mfa === false && serviceList[Number(e.target.value)].mfa === true) {
                        msgcode = messageCode['E0017'];
                      }
                    }
                    this.setState({ selectService: e.target.value, warnMessage: msgcode });
                  }}
                  disabled={linkedService.length > 50}
                >
                  {serviceList.map((item, index) => (
                    <option key={index} value={item.code}>
                      {item.disp}
                    </option>
                  ))}
                </Input>
                {this.state.warnMessage && (
                  <div className="error-message">
                    <p>{this.state.warnMessage}</p>
                  </div>
                )}
              </div>
              <Service
                serviceCode={selectService}
                imagePath={selectService !== '00' ? serviceList[Number(selectService)].img : ''}
                onRegist={(target) => {
                  this.registTarget = target;
                  this.setState({ confirmRegist: true });
                }}
                disabled={
                  this.mfa === false && (selectService !== '00' ? serviceList[Number(selectService)].mfa : false)
                }
              />
              <Confirmation
                show={confirmRegist}
                message="リンク登録してよろしいですか？"
                onApply={async () => {
                  // 認証情報生成システムリクエスト
                  try {
                    const params = {
                      portal_user_no: Number(this.context.user.attributes['custom:user_no']),
                      link_system_cd: this.registTarget.systemCd,
                      hyoji_mei_knj: this.registTarget.dispName,
                    };
                    const strParams = Buffer.from(JSON.stringify(params))
                      .toString('base64')
                      .replace(/\+/g, '-')
                      .replace(/\//g, '_')
                      .replace(/=+$/, '');
                    const url = `${process.env.REACT_APP_WAB_URL}/login/index.php?action_login=true&p=${process.env.REACT_APP_AUTH_GENERATE_URL}/eneAuth/registerTempAuthInfo?params=${strParams}`;
                    window.location.replace(url);
                  } catch (err) {
                    this.setState({ errorMessage: messageCode['E0001'] });
                    return;
                  }
                }}
                onHide={() => this.setState({ confirmRegist: false })}
              />
            </div>
            <div className="area-title mt-5">
              <h2>リンク済みサービス一覧</h2>
            </div>
            <div className="service-link pl-4 pr-4">
              {linkedService.map((item, index) => (
                <LinkedService
                  key={index}
                  serviceCode={item.link_system_cd}
                  dispName={item.hyoji_mei_knj}
                  authID={item.link_system_id}
                  imagePath={item.logo_image_path}
                  onRelease={(target) => {
                    this.releaseTarget = target;
                    this.setState({ confirmRelease: true });
                  }}
                />
              ))}
              <Confirmation
                show={confirmRelease}
                message="リンク解除してよろしいですか？"
                onApply={async () => {
                  this.setState({ confirmRelease: false });
                  const palam = {
                    headers: {
                      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    },
                    body: {
                      portal_user_no: Number(this.context.user.attributes['custom:user_no']),
                      link_system_cd: this.releaseTarget.systemCd,
                      link_system_id: this.releaseTarget.systemId,
                    },
                  };
                  API.del('HelloOGAPI', '/credential', palam)
                    .then(() => {
                      this.getData();
                    })
                    .catch((err) => {
                      if (err.response && 'status' in err.response) {
                        this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
                      } else {
                        this.setState({ errorMessage: messageCode['E0001'] });
                      }
                    });
                }}
                onHide={() => this.setState({ confirmRelease: false })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LinkConfig;
