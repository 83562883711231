import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { Hub } from 'aws-amplify';
import UserContext from './UserContext';
import Header from './Header';
import Footer from './Footer';
import Authenticator from './Authenticator';
import Home from './Home';
import LinkConfig from './LinkConfig';
import UserConfig from './UserConfig';
import Withdrawal from './Withdrawal';
import Logout from './Logout';
import Complete from './Complete';
import WithdrawalComplete from './WithdrawalComplete';
import ScrollToTop from './components/ScrollToTop';
import NotFoundPage from './NotFoundPage';
import SessionLost from './SessionLost';

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  };
  static contextType = UserContext;
  componentDidMount() {
    Hub.listen('auth', this.listener);
  }
  listener = (data) => {
    if (data.payload.event === 'tokenRefresh_failure') {
      this.props.history.replace('/sessionlost');
    }
  };
  render() {
    const { component: Component, ...rest } = this.props;
    const isAuthenticated = this.context.user && this.context.user.username ? true : false;
    const isLoaded = this.context.isLoaded;
    if (!isLoaded) return null;

    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <div className="contents">
              <Header />
              <Component {...props} />
              <Footer />
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }}
      />
    );
  }
}

/*eslint no-class-assign: */
PrivateRoute = withRouter(PrivateRoute);

class LoginRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  };
  static contextType = UserContext;
  render() {
    const { component: Component, ...rest } = this.props;
    const isAuthenticated = this.context.user && this.context.user.username ? true : false;
    const isLoaded = this.context.isLoaded;
    if (!isLoaded) return null;

    return (
      <Route
        {...rest}
        render={(props) => {
          return !isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }}
      />
    );
  }
}

const Routes = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <LoginRoute path="/login" exact component={Authenticator} />
      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/link" exact component={LinkConfig} />
      <PrivateRoute path="/user" exact component={UserConfig} />
      <PrivateRoute path="/withdrawal" exact component={Withdrawal} />
      <LoginRoute path="/logout" exact component={Logout} />
      <LoginRoute path="/complete" exact component={Complete} />
      <LoginRoute path="/withdrawalcomplete" exact component={WithdrawalComplete} />
      <Route path="/sessionlost" exact component={SessionLost} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);
export default Routes;
PrivateRoute.propTypes = {
  component: PropTypes.any,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
LoginRoute.propTypes = {
  component: PropTypes.any,
};
