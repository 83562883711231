import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback, InputGroupAddon, InputGroup } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import { messageCode } from './MessageCode';

import logo from './resource/img/header_logo.png';
import UserContext from './UserContext';
import Spinner from './components/Spinner';

class SignIn extends React.Component {
  state = {
    errorMessage: null,
    currentState: 'inputLogin',
    user: {},
    running: false,
    displayEyes: {
      password: false,
    },
  };
  static contextType = UserContext;

  toggleFishEye = ({ target }, stateName) => {
    target.classList.toggle('fa-eye');
    target.classList.toggle('fa-eye-slash');
    this.setState((prevState) => ({
      displayEyes: {
        ...prevState.displayEyes,
        [stateName]: !this.state.displayEyes[stateName],
      },
    }));
  };

  signIn = (values) => {
    const { history } = this.props;
    Auth.configure({
      authenticationFlowType: 'CUSTOM_AUTH',
    });
    this.setState({ errorMessage: null, running: true });
    Auth.signIn(values.loginid, values.password)
      .then((user) => {
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          // 二段階認証画面へ
          this.setState({ user, currentState: 'inputSecurityCode', running: false });
          window.scrollTo(0, 0);
        } else if (user.signInUserSession) {
          // トップ画面へ
          this.setState({ running: false });
          history.replace('/');
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
      })
      .catch((err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            // ユーザー無し
            this.setState({ errorMessage: messageCode['E0003'], running: false });
            break;
          case 'NotAuthorizedException':
            if (err.message === 'Incorrect username or password.') {
              // ログインID or パスワード違い
              this.setState({ errorMessage: messageCode['E0003'], running: false });
            } else {
              // アカウントロック
              // err.message:Password attempts exceeded.
              this.setState({
                errorMessage: messageCode['E0004'],
                running: false,
              });
            }
            break;
          case 'UserNotConfirmedException':
            // 未アクティベート
            this.setState({ errorMessage: messageCode['E0005'], running: false });
            break;
          case 'UserLambdaValidationException': {
            // Lambdaトリガーエラー
            const code = err.message.match(/E\d+/);
            if (code) {
              this.setState({ errorMessage: messageCode[code[0]], running: false });
            } else {
              this.setState({ errorMessage: messageCode['E0001'], running: false });
            }
            break;
          }
          default:
            // その他のエラー
            this.setState({ errorMessage: messageCode['E0001'], running: false });
            break;
        }
      });
  };
  challengeAnswer = (values) => {
    const { history } = this.props;
    this.setState({ errorMessage: null, running: true });
    Auth.sendCustomChallengeAnswer(this.state.user, values.authCode)
      .then((user) => {
        if (user.signInUserSession) {
          // サインイン成功
          this.setState({ running: false });
          history.replace('/');
        } else {
          this.setState({ errorMessage: messageCode['E0006'], running: false });
        }
      })
      .catch((err) => {
        if (err.code === 'NotAuthorizedException') {
          if (err.message === 'Incorrect username or password.') {
            // セキュリティコード3回間違い
            this.setState({ currentState: 'noRetry', running: false });
            window.scrollTo(0, 0);
          } else {
            // セキュリティコード有効期限切れ
            // err.message:Invalid session for the user.
            this.setState({
              errorMessage: messageCode['E0007'],
              running: false,
            });
          }
        } else if (err.code === 'UserLambdaValidationException') {
          // Lambdaトリガーエラー
          const code = err.message.match(/E\d+/);
          if (code) {
            this.setState({ errorMessage: messageCode[code[0]], running: false });
          } else {
            this.setState({ errorMessage: messageCode['E0001'], running: false });
          }
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
      });
  };
  render() {
    const { currentState, running, displayEyes } = this.state;
    if (currentState === 'inputLogin') {
      return (
        <div className="contents">
          <Spinner isLoading={running} />
          <div className="main-contents">
            <div className="login col-lg-6">
              <img className="mb-4" src={logo} alt="HelloOG!ポータル" />
              <div className="text-left btn-login text-pw-policy">
                リンク先サービスのセキュリティ強化に伴い、ログインパスワードの要件が
                <br />
                8桁以上から12桁以上に変更されております。
                <br />
                <br />
                リンク先サービスパスワード変更の際に必要なリンク再設定方法は
                <br />
                <a href={process.env.REACT_APP_HOWTO_CHANGE_PW_URL} rel="noreferrer" target="_blank">
                  こちら
                </a>
              </div>
              <div className="error-message">
                <p>{this.state.errorMessage}</p>
              </div>
              <Formik
                initialValues={{ loginid: '', password: '' }}
                onSubmit={(values) => {
                  this.signIn(values);
                }}
                validationSchema={Yup.object().shape({
                  loginid: Yup.string().required(messageCode['W0001']),
                  password: Yup.string().required(messageCode['W0001']),
                })}
              >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="form-label-group">
                      <Label for="loginid">ログインID</Label>
                      <Input
                        className="form-control half-width"
                        name="loginid"
                        id="loginid"
                        maxLength="20"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.loginid}
                        invalid={touched.loginid && errors.loginid ? true : false}
                      />
                      <FormFeedback>{errors.loginid}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="form-label-group">
                      <Label for="password">パスワード</Label>
                      <InputGroup>
                        <Input
                          className="form-control half-width"
                          type={displayEyes.password ? 'text' : 'password'}
                          name="password"
                          id="password"
                          maxLength="99"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          invalid={touched.password && errors.password ? true : false}
                        />
                        <InputGroupAddon addonType="append">
                          <i className="fa fa-eye fish-eye" onClick={(e) => this.toggleFishEye(e, 'password')} />
                        </InputGroupAddon>
                        <FormFeedback>{errors.password}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                    <Button
                      className="btn btn-login text-uppercase font-weight-bold mb-2"
                      variant="primary"
                      size="lg"
                      type="submit"
                      block
                    >
                      利用規約に同意してログインする
                    </Button>
                    <div className="text-center btn-login">
                      <a href={process.env.REACT_APP_RIYOKIYAKU_URL} rel="noreferrer" target="_blank">
                        利用規約
                      </a>
                      に同意の上ログインをお願いします。
                    </div>
                    <div className="mt-4">
                      <div className="text-center btn-login">
                        <a onClick={() => this.props.switchState('showForgotLoginId')}>IDをお忘れの方</a>
                      </div>
                      <div className="text-center btn-login">
                        <a onClick={() => this.props.switchState('showForgotPassword')}>パスワードをお忘れの方</a>
                      </div>
                    </div>
                    <Button
                      className="btn btn-login text-uppercase font-weight-bold mt-3 mb-2"
                      variant="success"
                      size="lg"
                      onClick={() => this.props.switchState('showSignUp')}
                      block
                    >
                      新規登録される方
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else if (currentState === 'inputSecurityCode') {
      return (
        <div className="contents">
          <Spinner isLoading={running} />
          <Header />
          <div className="main-contents">
            <div className="form-col col-lg-6">
              <h1 className="mb-4">二段階認証</h1>
              <p>
                ご登録いただいたメールアドレス宛に
                <br />
                二段階認証の確認メッセージを送信いたしました。
                <br />
                セキュリティコードの有効期限は３分です。
                <br />
                セキュリティコードを入力してログインしてください。
              </p>
              <div className="error-message">
                <p>{this.state.errorMessage}</p>
              </div>
              <Formik
                initialValues={{ authCode: '' }}
                onSubmit={(values) => {
                  this.challengeAnswer(values);
                }}
                validationSchema={Yup.object().shape({
                  authCode: Yup.string().required(messageCode['W0001']),
                })}
              >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="form-label-group">
                      <Label for="authCode">セキュリティコード</Label>
                      <Input
                        className="form-control half-width"
                        name="authCode"
                        id="authCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.authCode}
                        invalid={touched.authCode && errors.authCode ? true : false}
                      />
                      <FormFeedback>{errors.authCode}</FormFeedback>
                    </FormGroup>
                    <Button
                      className="btn btn-form text-uppercase font-weight-bold mb-2"
                      variant="primary"
                      size="lg"
                      type="submit"
                      block
                    >
                      ログインする
                    </Button>
                    <Button
                      className="btn btn-form text-uppercase font-weight-bold mb-2"
                      variant="secondary"
                      size="lg"
                      onClick={() => {
                        this.setState({ currentState: 'inputLogin', errorMessage: null, running: false });
                        window.scrollTo(0, 0);
                      }}
                      block
                    >
                      戻る
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else if (currentState === 'noRetry') {
      return (
        <div className="contents">
          <Header />
          <div className="main-contents">
            <div className="form-col col-lg-5">
              <p className="mb-5">
                セキュリティコードの認証ができませんでした。
                <br />
                再度ログイン画面からやり直してください。
              </p>
              <Button
                className="btn btn-form text-uppercase font-weight-bold mb-2"
                variant="secondary"
                size="lg"
                onClick={() => {
                  this.setState({ currentState: 'inputLogin', errorMessage: null });
                  window.scrollTo(0, 0);
                }}
                block
              >
                ログイン画面へ戻る
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }
}
export default SignIn;
SignIn.propTypes = {
  switchState: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
