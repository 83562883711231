import React from 'react';
import PropTypes from 'prop-types';
var Loader = require('react-loader');
import { CSSTransition } from 'react-transition-group';

class Spinner extends React.Component {
  static get propTypes() {
    return { isLoading: PropTypes.bool };
  }

  constructor(props) {
    super(props);
  }

  get options() {
    return {
      lines: 13,
      length: 20,
      width: 10,
      radius: 30,
      corners: 1,
      rotate: 0,
      direction: 1,
      color: '#fff',
      speed: 1,
      trail: 60,
    };
  }

  get styleForOverlay() {
    return {
      background: 'rgba(0, 0, 0, 0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 101,
      display: 'block',
    };
  }

  render() {
    return (
      <CSSTransition classNames="spinner" timeout={0}>
        {this.props.isLoading ? (
          <div style={this.styleForOverlay}>
            <Loader key="Loader" options={this.options}></Loader>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    );
  }
}

export default Spinner;
