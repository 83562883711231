import React from 'react';
import PropTypes from 'prop-types';
import { Auth, API } from 'aws-amplify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Spinner from './components/Spinner';
import { Confirmation } from './components/Confirmation';
import { messageCode } from './MessageCode';
import UserContext from './UserContext';

class Withdrawal extends React.Component {
  static contextType = UserContext;
  state = {
    errorMessage: null,
    reasonList: [],
    running: false,
    confirm: false,
    crl_no: 0,
  };
  withdrawal = async (values) => {
    const { updateCurrentUser } = this.context;
    const user = await Auth.currentAuthenticatedUser();
    const palam = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        portal_user_no: Number(user.attributes['custom:user_no']),
        portal_login_id: user.username,
        taikai_riyu_cd: values.reason,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        emailaddress: user.attributes.email,
        rec_mod_ctl_no: this.state.crl_no,
        rec_mod_pgm_id: 'P206001',
      },
    };
    this.setState({ errorMessage: null, running: true });
    API.del('HelloOGAPI', '/portaluser', palam)
      .then(() => {
        Auth.signOut()
          .then(async () => {
            await updateCurrentUser();
            this.setState({ running: false });
            this.props.history.replace('/withdrawalcomplete');
          })
          .catch(() => {
            this.setState({ errorMessage: messageCode['E0001'], running: false });
          });
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode], running: false });
        } else {
          this.setState({ errorMessage: messageCode['E0001'], running: false });
        }
      });
  };
  async componentDidMount() {
    // 退会理由マスタの取得
    API.get('HelloOGAPI', '/master/code', {
      queryStringParameters: {
        code_type_no: '0001',
      },
    })
      .then((response) => {
        const list = [{ code: '00', disp: '選択してください' }];
        response.results.forEach((element) => {
          list.push({ code: element.code_val_no, disp: element.nm_knj });
        });
        this.setState({ reasonList: list });
      })
      .catch(() => {
        this.setState({ errorMessage: messageCode['E0001'] });
      });
    // レコード更新制御／番号の取得
    API.get('HelloOGAPI', '/portaluser', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      queryStringParameters: {
        portal_user_no: Number(this.context.user.attributes['custom:user_no']),
      },
    })
      .then((response) => {
        this.setState({ crl_no: response.results.rec_mod_ctl_no });
      })
      .catch((err) => {
        if (err.response && 'status' in err.response) {
          this.setState({ errorMessage: messageCode[err.response.data.resultCode] });
        } else {
          this.setState({ errorMessage: messageCode['E0001'] });
        }
      });
  }

  render() {
    const { reasonList, running, confirm } = this.state;
    return (
      <div className="main-contents">
        <Spinner isLoading={running} />
        <div className="form-col col-lg-6">
          <h1 className="mb-4">ユーザー登録の削除</h1>
          <p className="mb-5">
            HelloOG!ポータルのユーザ登録を削除される場合は、
            <br />
            今後のサービス向上のため、アンケートにご協力ください。
            <br />
            <br />
            一度削除されますと、登録情報は全て削除されますのでご注意ください。
            <br />
            <br />
            再びHelloOG!ポータルをご利用いただく際は、ユーザ情報の再登録をお願いいたします。
          </p>
          <div className="error-message">
            <p>{this.state.errorMessage}</p>
          </div>
          <h3 className="form-title">アンケート</h3>
          <Formik
            initialValues={{ reason: '00' }}
            onSubmit={() => {
              this.setState({ confirm: true });
            }}
            validationSchema={Yup.object().shape({
              reason: Yup.string().notOneOf(['00'], messageCode['W0010']),
            })}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup className="form-label-group">
                  <Label for="reason" className="required">
                    削除理由
                  </Label>
                  <Input
                    className="form-control"
                    type="select"
                    name="reason"
                    id="reason"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reason}
                    invalid={touched.reason && errors.reason ? true : false}
                  >
                    {reasonList.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.disp}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{errors.reason}</FormFeedback>
                </FormGroup>
                <Button
                  className="btn btn-form text-uppercase font-weight-bold mb-2"
                  variant="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  削除する
                </Button>
                <Confirmation
                  show={confirm}
                  message="削除手続きをされますと、HelloOG!ポータルはご利用できなくなります。\nユーザ登録を削除してよろしいですか？"
                  onApply={() => {
                    this.setState({ confirm: false });
                    this.withdrawal(values);
                  }}
                  onHide={() => this.setState({ confirm: false })}
                />
                <Button
                  className="btn btn-form text-uppercase font-weight-bold mb-2"
                  variant="secondary"
                  size="lg"
                  onClick={() => this.props.history.replace('/user')}
                  block
                >
                  戻る
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
export default Withdrawal;
Withdrawal.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
