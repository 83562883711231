import React, { useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ForgotLoginId from './ForgotLoginId';

export function Authenticator(props) {
  const [currentState, setCurrentState] = useState('showSignIn');
  window.scrollTo(0, 0);
  return (
    <>
      {currentState === 'showSignIn' && <SignIn {...props} switchState={setCurrentState} />}
      {currentState === 'showSignUp' && <SignUp {...props} switchState={setCurrentState} />}
      {currentState === 'showForgotPassword' && <ForgotPassword {...props} switchState={setCurrentState} />}
      {currentState === 'showForgotLoginId' && <ForgotLoginId {...props} switchState={setCurrentState} />}
    </>
  );
}
export default Authenticator;
