import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Label, Input, FormFeedback } from 'reactstrap';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { messageCode } from '../MessageCode';

const description = (code) => {
  // HelloOG!
  if (code === '01') return '業務用工業用のお客さま向けの無料の会員サイトで、ガス使用量・請求額等がご確認できます。';
  // Eneflex
  if (code === '02') return 'ガスヒーポンの運転状況の見える化などの情報をWeb上で提供するサービスです。';
  // ekul
  if (code === '03') return 'ガス・電気などの使用量をリアルタイムに計測し、気づき情報を即時に発信するサービスです。';
  // Webエコーライン
  if (code === '04') return ''; //説明文なし
  // 蓄電池AI制御システム
  if (code === '05') return ''; //説明文なし
  // Energy Brain
  if (code === '06') return '業工用お客さま向けの遠隔自動制御エネルギーマネジメントシステム';

  return null;
};

export const Service = (props) => {
  return (
    props.serviceCode !== '00' && (
      <Formik
        initialValues={{ dispName: '' }}
        onSubmit={(values) => {
          props.onRegist({ systemCd: props.serviceCode, dispName: values.dispName });
        }}
        validationSchema={Yup.object().shape({
          dispName: Yup.string()
            .matches(/^[^\x20-\x7e]*$/, messageCode['W0011'])
            .required(messageCode['W0001']),
        })}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row service-col no-gutters">
              <div className="col-12 pr-4">
                <div className="row no-gutters">
                  <div className="col-6 pr-4">
                    <Image width="100%" src={props.imagePath} />
                    <div>{description(props.serviceCode)}</div>
                  </div>
                  <div className="col-6 py-4">
                    <Label for="dispName">表示名</Label>
                    <Input
                      name="dispName"
                      id="dispName"
                      maxLength="20"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dispName}
                      invalid={touched.dispName && errors.dispName ? true : false}
                    />
                    <FormFeedback>{errors.dispName}</FormFeedback>
                    <Button
                      className="btn text-uppercase font-weight-bold mt-5 mb-2 py-4"
                      variant="primary"
                      size="lg"
                      type="submit"
                      disabled={props.disabled}
                      block
                    >
                      リンク設定
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
};

Service.propTypes = {
  serviceCode: PropTypes.string,
  imagePath: PropTypes.string,
  onRegist: PropTypes.func,
  disabled: PropTypes.bool,
};

export const LinkedService = (props) => (
  <div className="row service-col no-gutters">
    <div className="col-sm-9 pr-4">
      <div className="row no-gutters">
        <div className="col-8 pr-4">
          <Image width="100%" src={props.imagePath} />
          <div>{description(props.serviceCode)}</div>
        </div>
        <div className="col-4 py-4">
          <p>{props.dispName}</p>
          <p>認証ID：{props.authID}</p>
        </div>
      </div>
    </div>
    <div className="col-sm-3 py-4">
      <Button
        className="btn text-uppercase font-weight-bold mb-2 py-4"
        variant="secondary"
        size="lg"
        onClick={() => props.onRelease({ systemCd: props.serviceCode, systemId: props.authID })}
        block
      >
        リンク解除
      </Button>
    </div>
  </div>
);

LinkedService.propTypes = {
  serviceCode: PropTypes.string,
  dispName: PropTypes.string,
  authID: PropTypes.string,
  imagePath: PropTypes.string,
  onRelease: PropTypes.func,
};

export const LinkedServiceThumb = (props) => (
  <a onClick={props.onEnter}>
    <div className="row link-container no-gutters">
      <div className="col-5 offset-1 py-5">
        <Image src={props.imagePath} />
      </div>
      <div className="col-5 offset-1 link-text py-5">
        <p>{props.dispName}</p>
        <p>認証ID：{props.authID}</p>
      </div>
      <div className="offset-1">
        <p>利用規約もしくは契約内容に同意してログイン</p>
      </div>
    </div>
  </a>
);

LinkedServiceThumb.propTypes = {
  serviceCode: PropTypes.string,
  dispName: PropTypes.string,
  authID: PropTypes.string,
  imagePath: PropTypes.string,
  onEnter: PropTypes.func,
};
