import React from 'react';
import Header from './Header';
import Footer from './Footer';

const WithdrawalComplete = () => (
  <div className="contents">
    <Header />
    <div className="main-contents">
      <div className="form-col">
        <h1 className="mb-4">ユーザ登録の削除手続き完了</h1>
        <p className="mb-5">
          HelloOG!ポータルのユーザ登録の削除手続きが
          <br />
          完了いたしました。
          <br />
          ご登録いただいたメールアドレス宛に削除完了メールを送信いたしました。
          <br />
          ご確認をお願いいたします。
        </p>
      </div>
    </div>
    <Footer />
  </div>
);
export default WithdrawalComplete;
