import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import UserContext from './UserContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import logo from './resource/img/header_logo.png';

class Header extends React.Component {
  state = {
    navExpanded: false,
  };
  static contextType = UserContext;
  signOut() {
    const { updateCurrentUser } = this.context;
    Auth.signOut()
      .then(async () => {
        await updateCurrentUser();
        this.props.history.replace('/logout');
      })
      .catch(() => null);
  }
  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }
  closeNav() {
    this.setState({ navExpanded: false });
  }
  constructor(props) {
    super(props);
    this.setNavExpanded = this.setNavExpanded.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }
  render() {
    const isAuthenticated = this.context.user && this.context.user.username ? true : false;
    const isLoaded = this.context.isLoaded;
    return (
      <Container className="header-container" fluid>
        <Row className="header">
          <Col>
            <Navbar expand="xl" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
              <Navbar.Brand
                onClick={() => {
                  this.props.history.replace('/');
                }}
              >
                <img src={logo} alt="HelloOG!ポータル" />
              </Navbar.Brand>
              {isLoaded ? (
                isAuthenticated ? (
                  <>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                      <Nav>
                        <div className="nav-item ml-4">
                          <Button
                            variant="outline-primary"
                            size="lg"
                            onClick={() => {
                              this.props.history.replace('/');
                              this.closeNav();
                            }}
                          >
                            <i className="material-icons material-icons-filled">home</i>
                            トップ
                          </Button>
                        </div>
                        <div className="nav-item ml-4">
                          <Button
                            variant="outline-primary"
                            size="lg"
                            onClick={() => {
                              this.props.history.replace('/link');
                              this.closeNav();
                            }}
                          >
                            <i className="fa fa-link"></i>
                            リンク設定
                          </Button>
                        </div>
                        <div className="nav-item ml-4">
                          <Button
                            variant="outline-primary"
                            size="lg"
                            onClick={() => {
                              this.props.history.replace('/user');
                              this.closeNav();
                            }}
                          >
                            <i className="fa fa-user"></i>
                            ユーザー設定
                          </Button>
                        </div>
                        <div className="nav-item ml-4">
                          <Button variant="outline-primary" size="lg" onClick={() => this.signOut()}>
                            <i className="fa fa-sign-out"></i>
                            ログアウト
                          </Button>
                        </div>
                      </Nav>
                    </Navbar.Collapse>
                  </>
                ) : null
              ) : null}
            </Navbar>
          </Col>
        </Row>
        <Row className="user-stat-container">
          <Col md={4} className="user-status mt-3 mb-3">
            {isAuthenticated ? (
              <span>{this.context.user.attributes['name']} 様</span>
            ) : (
              <span style={{ visibility: 'hidden' }}> 様</span>
            )}
          </Col>
          {isAuthenticated ? (
            <Col md={8} className="user-status mt-3 mb-3">
              <span>前回ログイン日時 : {this.context.user.attributes['custom:last_login_time']}</span>
            </Col>
          ) : null}
        </Row>
      </Container>
    );
  }
}

export default withRouter(Header);
Header.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
